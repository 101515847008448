<template>
  <div>
    <FormTabLanguages v-show="checkIfHasTranslatable" :v="v"></FormTabLanguages>
    <b-form-row>
      <template v-for="(schemaField, schemaFieldIndex) in schemaJson.fields">
        <div
          v-if="schemaField.divider != false"
          class="w-100"
          v-bind:key="'divider_' + schemaFieldIndex"
        ></div>
        <template
          v-if="schemaField.translatable && schemaField.translatable == true"
        >
          <FormBuilderInnerTranslatable
            v-for="(lang, langIndex) in getLanguages"
            v-bind:key="'form_field_i18n_' + schemaFieldIndex + '_' + langIndex"
            :fieldLang="lang.lang"
            :form="form"
            :schemaField="schemaField"
            :schemaFieldIndex="schemaFieldIndex"
            :v="v"
            v-show="getTabLanguage.lang == lang.lang"
          ></FormBuilderInnerTranslatable>
        </template>
        <template v-else>
          <FormBuilderInnerPlain
            v-bind:key="'form_field_plain_' + schemaFieldIndex"
            :form="form"
            :schemaField="schemaField"
            :schemaFieldIndex="schemaFieldIndex"
            :v="v"
          ></FormBuilderInnerPlain>
        </template>
      </template>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FormTabLanguages from "@/view/content/forms/FormTabLanguages.vue";
import FormBuilderInnerTranslatable from "@/view/content/forms/FormBuilderInnerTranslatable.vue";
import FormBuilderInnerPlain from "@/view/content/forms/FormBuilderInnerPlain.vue";

export default {
  name: "FormBuilder",
  props: {
    /**
     * All rows out of the model needed for the form.
     * e.g. id, email, name, phone, active
     */
    form: Object,
    /**
     * Fields in the form.
     * Object with all field related parameters e.g. id, type, inputType, label, model, min, max, required, hint, validator, placeholder, default, clearable, readonly
     */
    schemaJson: Object,
    /**
     * Vuelidate state $v
     */
    v: {}
  },
  data() {
    return {};
  },
  components: {
    FormTabLanguages,
    FormBuilderInnerTranslatable,
    FormBuilderInnerPlain
  },
  mounted() {},
  computed: {
    ...mapGetters(["getLanguages", "getTabLanguage"]),
    checkIfHasTranslatable() {
      let findTranslatable = this.schemaJson.fields.find(val => {
        return val.translatable === true;
      });
      if (findTranslatable) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {}
};
</script>
