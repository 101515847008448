<template>
  <div>
    <slot v-if="clearable" name="clearable"><a @click="setRadioNull">X {{$t("LABELS.CLEAR")}}</a></slot>
    <v-radio-group
      v-bind="$attrs"
      :value="value"
      @change="setSelected"
      :mandatory="false"
      :row="rowHorizontal"
    >
      <template v-for="(option, index) in options">
        <v-radio :value="option.id" v-bind:key="'radiogroup-' + name + index">
          <template v-slot:label>
            <div>
              {{ option.name }}
              <strong class="success--text" v-if="option.description"
                >({{ option.description }})</strong
              >
            </div>
          </template>
        </v-radio>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "FormRadio",
  inheritAttrs: false,
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    /**
     * All options existing in the radio group options
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * V-Model
     */
    value: {},
    /**
     * Vertical or Horizontal align of the radio buttons in the group
     */
    rowHorizontal: Boolean
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    isChecked() {
      return !!this.value;
    }
  },
  methods: {
    setRadioNull(){
      let vm = this;
      let value = null;
      vm.setSelected(value);
    },
    setSelected(value) {
      // alert(value);
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>
