<template>
  <b-col
    :sm="schemaField.colSm ? schemaField.colSm : ''"
    v-if="
      !('hidden' in schemaField) ||
        ('hidden' in schemaField && schemaField.hidden == false)
    "
  >
    <b-form-group
      v-bind:id="schemaField.groupId"
      v-bind:label="$t(schemaField.i18n.label)"
      v-bind:label-for="schemaField.id"
      v-bind:description="$t(schemaField.i18n.description)"
    >
      <FormInput
        v-if="schemaField.type == 'input'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model + ':' + fieldLang"
        :description="schemaField.description"
        :required="schemaField.required"
        :feedback_id="schemaField.feedback_id"
        :i18n="schemaField.i18n"
        :validations="schemaField.validations"
        :validateState="validateState([schemaField.model + ':' + fieldLang])"
        v-model="form[schemaField.model + ':' + fieldLang]"
      ></FormInput>

      <FormTextarea
        v-if="schemaField.type == 'textarea'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model + ':' + fieldLang"
        :required="schemaField.required"
        :feedback_id="schemaField.feedback_id"
        :i18n="schemaField.i18n"
        :validations="schemaField.validations"
        :validateState="validateState([schemaField.model + ':' + fieldLang])"
        v-model="form[schemaField.model + ':' + fieldLang]"
      ></FormTextarea>

      <FormSelect
        v-if="schemaField.type == 'select'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model + ':' + fieldLang"
        :options="schemaField.options"
        :clearable="schemaField.clearable"
        :reduceKey="schemaField.reduceKey"
        v-model="form[schemaField.model + ':' + fieldLang]"
      ></FormSelect>

      <FormSwitch
        v-if="schemaField.type == 'switch'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model + ':' + fieldLang"
        v-model="form[schemaField.model + ':' + fieldLang]"
      ></FormSwitch>

      <FormRadio
        v-if="schemaField.type == 'radio'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model + ':' + fieldLang"
        :options="schemaField.options"
        :rowHorizontal="schemaField.rowHorizontal"
        v-model="form[schemaField.model + ':' + fieldLang]"
      ></FormRadio>

      <FormDatepicker
        v-if="schemaField.type == 'datepicker'"
        :id="schemaField.id"
        :type="schemaField.inputType"
        :name="schemaField.model"
        :i18n="schemaField.i18n"
        v-model="form[schemaField.model]"
      ></FormDatepicker>
    </b-form-group>
  </b-col>
</template>

<script>
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormTextarea from "@/view/content/forms/components/FormTextarea.vue";
import FormRadio from "@/view/content/forms/components/FormRadio.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

export default {
  name: "FormBuilderInnerTranslatable",
  props: {
    /**
     * All rows out of the model needed for the form.
     * e.g. id, email, name, phone, active
     */
    form: Object,
    /**
     * Field related parameters.
     * e.g. id, type, inputType, label, model, min, max, required, hint, validator, placeholder, default, clearable, readonly
     */
    schemaField: Object,
    /**
     * index from v-for loop of all fields
     */
    schemaFieldIndex: Number,
    /**
     * Vuelidate state $v
     */
    v: {},
    /**
     * language key e.g. (bg, en, fr)
     */
    fieldLang: String
  },
  data() {
    return {};
  },
  components: {
    FormSelect,
    FormSwitch,
    FormInput,
    FormTextarea,
    FormRadio,
    FormDatepicker
  },
  mounted() {},
  computed: {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
